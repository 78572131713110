<template>
<div>
<v-row no-gutters>

    <div v-if="!consented" class="pb-4">
        <v-col cols="12" class="pa-0">
            <h1 class="text-center pt-4">
                <span class="text-blue-grey-darken-3">Publish or Comparish Consent Form</span>
            </h1>
        </v-col>
        <v-col cols="12" class="pt-4">
        <v-card class="text-left pt-0 mb-0" elevation="0">
        <p class="pb-0">
            Please read and complete this consent form to begin participation in the study:<br><br>
            <b>"Assessing the Publication Preferences of Faculty, Researchers, and Students"</b><br>
            This study, conducted by researchers from the University of Colorado Boulder (CU IRB protocol no. 23-0454), has two main parts: the Publish or Comparish game to learn about publication preferences and a questionnaire to contextualize these preferences. In total, participation takes 5 - 10 minutes.
            <br><br>
            <b>Purpose of the Study</b><br>
            The purpose of the study is to investigate the publication preferences of researchers. Participants use pairwise comparisons to rank publication venues and these individual responses help form an aggregate, citation-independent assessment of venues. We also ask general demographic questions to contextualize preferences and see how responses vary by field, career stage, etc. We hope to collect a representative sample of most or all major academic fields and expect about 20,000 participants.
            <br><br>
            <b>Explanation of Procedures</b><br>
            This study consists of two major parts. The first is an online game in which you select places you would publish and then rank them via pairwise comparisons. The second is an online survey in which you answer additional demographic questions about yourself. Each part is estimated to take about 5 minutes.
            <br><br>
            <b>Voluntary Participation and Withdrawal</b><br>
            Whether or not you take part in this research is your choice. You can leave the research at any time and it will not be held against you. Data collected to the point of withdrawal may be used in analysis. You may email <i style="color:blue">facultystudy@colorado.edu</i> to request the deletion of your data.<br><br>
            The person in charge of the research study can remove you from the research study without your approval. Possible reasons for removal include responding at a speed that suggests thoughtless responses.<br><br>
            If you are a CU Boulder student or employee, taking part in this research is not part of your class work or duties. You can refuse to enroll, or withdraw after enrolling at any time, with no effect on your class standing, grades, or job at CU Boulder. You will not be offered or receive any special consideration if you take part in this research.
            <br><br>
            <b>Confidentiality</b><br>
            Information obtained about you for this study will be kept confidential to the extent allowed by law. Research information that identifies you may be shared with the University of Colorado Boulder Institutional Review Board (IRB) and others who are responsible for ensuring compliance with laws and regulations related to research, including people on behalf of the Office for Human Research Protections. The information from this research may be published for scientific purposes; however, your identity will not be given out.<br><br>
            Data associated with the online application is stored in a secure online database and survey data is stored in Qualtrics. The more sensitive demographic data stored in Qualtrics is only individually identifiable by linking that data with the online database. This data will be stored for the duration of analysis.
            <br><br>
            <b>Payment for Participation</b><br>
            You will not be paid to be in this study
            <br><br>
            <b>Questions</b><br>
            If you have questions, concerns, or complaints, or think the research has hurt you, talk to the research team at <i style="color:blue">facultystudy@colorado.edu</i><br><br>
            This research has been reviewed and approved by an IRB. You may talk to them at (303) 735-3702 or <i style="color:blue">irbadmin@colorado.edu</i> if: Your questions, concerns, or complaints are not being answered by the research team. You cannot reach the research team. You want to talk to someone besides the research team. You have questions about your rights as a research subject. You want to get information or provide input about this research.
        </p>
        </v-card>
        </v-col>


        <v-form v-model="valid" ref="form1" @submit="submitConsent" class="mb-0 pb-0">
        <v-row no-gutters justify="center">
        <v-col cols="12" sm="6" class="pa-0">
            <v-card class="text-center mt-2 px-2" elevation="0">
            <v-text-field
                id="formFirst"
                v-model="firstName"
                :rules="rules"
                label="First Name"
                variant="underlined"
                required
                hide-details="true"
                color="blue-grey-darken-1"
            ></v-text-field>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
            <v-card class="text-center mt-2 px-2" elevation="0">
            <v-text-field
                id="formLast"
                v-model="lastName"
                :rules="rules"
                label="Last Name"
                variant="underlined"
                required
                hide-details="true"
                color="blue-grey-darken-1"
            ></v-text-field>
            </v-card>
        </v-col>
        <v-col cols="12" class="pa-0 mt-4" id="formCheckbox">
            <v-checkbox class ="mb-8" v-model="checkedConsent" label="I have read and understood the consent form and freely give my consent to participate in this study"></v-checkbox>
        </v-col>
        <v-col cols="12" class="pa-0 d-flex justify-center">
            <v-card class="mt-0 sticky d-flex justify-center" elevation="0" width="800px" height="100px">
            <v-btn class="mt-4" style="font-weight:bold"
            width="800px"
            color="blue"
            size="large"
            type="submit">Start</v-btn>
            </v-card>
        </v-col>
        </v-row>
        </v-form>


        
    </div>
    <div v-else>
    <v-col cols="12" class="pt-4">
    <v-card class="text-left pt-0 mb-0" elevation="0">
    <p class="pb-0">
        To begin, please answer the following questions to help us get a sense of the places you might publish. 
        We will use this information to suggest other venues in the discover stage.
    </p>
    </v-card>
    </v-col>
    <v-col cols="12" class="pa-0" align-self="end">
    <v-form v-model="valid" ref="form2" @submit="submitInfo" class="mb-0 pb-0">
    <v-row no-gutters justify="center">
         <v-col cols="12" sm="6" class="pa-0">
            <v-card class="text-center mt-6 px-2" elevation="0">
            <v-select
                v-model="rank"
                :rules="rules"
                label="Current Academic Rank"
                :items="['Undergraduate', 'Masters Student', 'PhD Student', 'Postdoctoral Researcher', 
                'Assistant Professor', 'Associate Professor', 'Full Professor','Other']"
                variant="underlined"
                required
                hide-details="true"
                color="blue-grey-darken-1"
            ></v-select>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
            <v-card class="text-center mt-6 px-2" elevation="0">
            <v-select
                v-model="field"
                :rules="rules"
                label="Field (best approximation)"
                :items="['Art',
                        'Biology',
                        'Business',
                        'Chemistry',
                        'Computer science',
                        'Economics',
                        'Engineering',
                        'Environmental science',
                        'Geography',
                        'Geology',
                        'History',
                        'Materials science',
                        'Mathematics',
                        'Medicine',
                        'Philosophy',
                        'Physics',
                        'Political science',
                        'Psychology',
                        'Sociology']"
                variant="underlined"
                required
                hide-details="true"
                color="blue-grey-darken-1"
            ></v-select>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" class="pa-0">
            <v-card class="text-center mt-6 px-2" elevation="0">
            <v-select
                v-model="subfield"
                no-data-text="Please select a field."
                :rules="rules"
                label="Subfield"
                :items="subfieldItems"
                variant="underlined"
                required
                hide-details="true"
                color="blue-grey-darken-1"
            ></v-select>
            </v-card>
        </v-col>
        <v-col v-for="n in 3" :key="n" cols="12" class="px-2">
            <h3 class="text-center pt-12 ">{{this.search.headers[n-1]}}</h3>
            <v-autocomplete justify="center" align="center" 
            class="search pb-0 ma-0 px-0"
            v-model="search.journal[n-1]"
            :items="search.journals"
            item-title="name"
            item-value="id"
            v-model:search="searchField"
            return-object
            :clearable="false"
            required
            :rules="rules2"
            label="Click & Enter Venue Name"
            :single-line="true"
            :hide-details="true"
            :hide-no-data="true"
            density="comfortable"
            variant="underlined"
            prepend-inner-icon="mdi-book-education"
            color="blue-grey-darken-1"
            @update:modelValue="searchChange()"
            >
            </v-autocomplete>
        </v-col>
        <v-col cols="3" class="pa-0 d-flex justify-center">
            <v-card class="mt-8 mb-2" elevation="0">
            <v-btn class="mt-0" 
            width="150px"
            color="blue"
            variant="tonal"
            type="submit">continue</v-btn>
            </v-card>
        </v-col>
    </v-row>
    </v-form>
    </v-col>
    </div>
</v-row>
</div>
</template>

<script>
import axios from 'axios';

export default {
    emits: ["submit-info","update-progress",
    "update:consented","update:knowns", "update:unknowns", 
    "update:candidate", "update:candidates", "update:pair"],
    props: ["code", "knowns", "unknowns", "candidate", "candidates", "pair","consented"],
  data: () =>({
    rules: [
            v => !!v || 'Required',
            v => v.length <= 50 || 'No more than 50 characters',
        ],
    rules2: [
            v => !!v || 'Required',
        ],
    firstName: '',
    lastName: '',
    rank: null,
    field: null,
    subfield: null,
    email: '',
    valid: false,
    previewHeight: 187 + 24,
    previewWidth: 336,
    searchField: null,
    search: {
        journal: [null,null,null],
        journals:[],
        searchColor: "blue",
        isLoading: false,
        height: "40px",
        selectCount: 0,
        headers: ["In your field, what's a top venue you'd like to publish in?",
                  "If you weren't aiming for a top venue, where might you publish?",
                  "If you didn't aim as high as either of these venues, where might you publish?"],
    },
    checkedConsent: false,
    subfieldItems: [],
    debounce: null,

    
  }),
  watch: {
        searchField(a) {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
            this.search.isLoading = true
            const path = process.env.VUE_APP_BASE_URL+'/searchjournals';
            if (a !== null && a !== "" && this.search.selectCount === 0){
                axios.post(path, {string: a})
                .then((res) => {
                    const new_journals = [];
                    for (let i = 0; i < res.data.length; i++){
                        if (!this.search.journal.map(x => {if(x){return x['name']} else{return x}}).includes(res.data[i][1])){
                            new_journals.push({"id":res.data[i][0],
                                    "name":res.data[i][1], "valid":true})
                        }
                }
                this.search.journals = new_journals;
                })
            } else {
                this.search.journals = [];
                this.search.selectCount = 0
            }
            this.search.isLoading = false;
            }, 500)
        },
        field(a){
            const path = process.env.VUE_APP_BASE_URL+'/subfield';
            axios.post(path, {field: a}).then((res) => {
                this.subfieldItems = res.data["subfields"]
                this.subfield = null
            })
        }
    },
  methods: {
    async submitInfo (e) {
            e.preventDefault();
            await this.$refs.form2.validate()
            if (this.valid && this.search.journal.every(e => e !== null)){
                const path = process.env.VUE_APP_BASE_URL+'/signup';
                const payload = {code: this.code,
                                field: this.field,
                                subfield: this.subfield,
                                rank: this.rank,
                                journals: this.search.journal};
                await axios.post(path, payload)
                    .then((res) => {
                        this.$emit('update:knowns', res.data.knowns)
                        this.$emit('update:unknowns', res.data.unknowns)
                        this.$emit('update:candidates', res.data.candidates)
                        this.$emit('update:pair', res.data.pair)
                        this.$emit('update:candidate', res.data.newCandidate)
                        this.$emit('update-progress', 0, 1)
                        this.$emit('submit-info', 1)
                    })
                }
        },
    async submitConsent (e){
        e.preventDefault()
        await this.$refs.form1.validate()
        if (this.valid && this.checkedConsent === true){
            
            const path = process.env.VUE_APP_BASE_URL+'/consent';
            const payload = {code: this.code,
                                first: this.firstName, 
                                last: this.lastName,
                                };
             await axios.post(path, payload)
                    .then(() => {
                        axios.post(process.env.VUE_APP_BASE_URL+'/queryopenalex', payload)
                        this.$emit('update:consented', true)   
                        this.scroll()
                    })              
        } else {
            document.getElementById("formCheckbox").scrollIntoView()
            if (this.firstName === ''){
                document.getElementById("formFirst").focus()
            } else if (this.lastName === ''){
                document.getElementById("formLast").focus()  
            }
        }

    },
    async searchChange(){
        this.search.selectCount = 1
        },
    scroll() {
        window.scrollTo(0,0);
    },
    
    }
}
</script>

<style scoped>
.sticky {
  width: 150px;
  position: fixed;
  bottom: -80px;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);  
}
</style>